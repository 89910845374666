var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-main",
        [
          _c("head-layout", {
            attrs: {
              "head-btn-options": _vm.headBtnOptions,
              "head-title": "基本信息",
            },
            on: { "head-cancel": _vm.headCancel },
          }),
          _c("form-layout", {
            ref: "formLayout",
            attrs: { column: _vm.formColumn, dataForm: _vm.dataForm },
            on: {
              "update:dataForm": function ($event) {
                _vm.dataForm = $event
              },
              "update:data-form": function ($event) {
                _vm.dataForm = $event
              },
            },
          }),
          _c(
            "el-tabs",
            {
              attrs: { type: "border-card" },
              on: { "tab-click": _vm.handleClick },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "视频", name: "videoFrequency" } },
                [
                  _c("videoFrequency", {
                    ref: "videoTable",
                    attrs: { type: "view" },
                  }),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "课件", name: "courseware" } },
                [
                  _c("courseware", {
                    ref: "courseware",
                    attrs: { type: "view" },
                  }),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "试题", name: "testQuestions" } },
                [
                  _c("testQuestions", {
                    ref: "testQuestions",
                    attrs: { type: _vm.type, tainDetail: _vm.detail },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "qmDialog",
          attrs: {
            title: "我的培训",
            "modal-append-to-body": false,
            visible: _vm.videoShow,
            "before-close": _vm.videoHandleClose,
            fullscreen: _vm.fullscreen,
            top: "5vh",
            width: "80%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.videoShow = $event
            },
          },
        },
        [
          _c("div", { staticClass: "positionabs" }, [
            _c("i", {
              staticClass: "el-icon-full-screen",
              on: {
                click: function ($event) {
                  return _vm.fullClick()
                },
              },
            }),
          ]),
          _vm.videoShow ? _c("VideoDialog", { ref: "VideoDialog" }) : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }